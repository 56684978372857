<template>
  <div>
    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: PREFIX+'s' }">{{$t('label_menu_pattern')}}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{  $t('label_footer')  }}</b-breadcrumb-item>
      </div>
    </div>
    <b-col cols="12" md="12">
      <b-card no-body class="mb-0">
        <!-- Header -->
        <div class="d-flex  mt-1 px-2 py-1">
          <h5 class="mb-0">
            {{ action == 'editing'? $t('label_footer') : $t('label_footer') }}
          </h5>
        </div>

        <validation-observer
                #default="{ handleSubmit, invalid }"
                :ref="MODULE_PREFIX + '_FORM'"
                tag="form"
                class="p-2"
        >

          <validation-provider

                  #default="validationProps"
                  :name="$t('label_content')"
                  rules="required"
                  slim
          >
            <b-form-group
                    :label="$t('label_content')"

            >
              <b-form-textarea
rows="10"
                      :state="getValidationState(validationProps)"
                      v-model="itemData.pattern_footer"
              ></b-form-textarea>

              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <div class="d-flex mt-3 justify-content-end mb-2">
            <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
            >
              {{ action == 'editing'? $t('label_save') : $t('label_save') }}
            </b-button>
            <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    v-on:click="$router.go(-1)"
            >

              {{ $t('label_cancel') }}

            </b-button>
          </div>

        </validation-observer>

      </b-card>
    </b-col>

  </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea,
    } from 'bootstrap-vue'
    import {MODULE_PREFIX, MODULE_PREFIX as PREFIX, SHORTCODE_PREFIX} from './moduleHelper'


    export default {
        name: "editItem.vue",
        components: {

            BSidebar,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,

            BFormTextarea,

            ValidationProvider,
            ValidationObserver,

        },
        data() {
            return {

                PREFIX,
                MODULE_PREFIX,
                SHORTCODE_PREFIX,

                itemData: {
                    pattern_footer:''
                },

                action: 'adding',

                snowOption: {
                    theme: 'snow',
                },

            }
        },
        created() {

            this.initData();

        },
        methods: {

            initData() {
                this.async('get', '/' + this.MODULE_PREFIX + '_footer', {}, function (resp) {

                    this.itemData.pattern_footer = resp.data.footer;

                });

            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_footer', this.itemData, function (resp) {

                    });
                }

            },

        }
    }
</script>

